import React, { Component} from 'react'
import Layout from './layout'
import MyImage from './myImage.js'
import projects from "../data/projects"
import { Link } from 'gatsby'

class ProjectLayout extends Component {

  constructor(props) {
    super(props)

    this.state = {
      id: projects
        .find(project => project.id === this.props.id),
    }
  }


  render() {
    return (
      <Layout>
        <div className="project-page">
          <div className="project-hero">
            <MyImage className="hero-image" filename={this.state.id.hero} />
          </div>
          <div className="container project-title">
            <h1 className="title">{this.state.id.title}</h1>
            <p className="client">{this.state.id.client}</p>
            <p className="project-date">{this.state.id.date}</p>
          </div>
          <div className="case-study-content">
            {this.props.children}
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProjectLayout
